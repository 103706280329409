import React, { useEffect, useCallback, useState, useRef } from 'react'
import propTypes from 'prop-types'
import { renderable } from 'src/common/propTypes'
import Header from './ActualHeader'
import Footer from './Footer'
import styles from './index.module.scss'
import { useAnalytics } from '../../common/analytics'
import CustomJsonLd from '../shared/CustomJsonLd'
import { reviewsJsonLdData } from 'src/common/constants/reviewsJsonLd'
import useOnViewPortCtas from '../../common/hooks/useOnViewPortCtas'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { featureToggles } from 'src/common/constants/featureToggles'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { useLeadId } from 'src/common/lead-id'
import axios from 'axios'
import { usePersonalizedData } from 'src/common/hooks/usePersonalizedData'
import { useTrustStripsData } from 'src/common/hooks/useTrustStripsData'

const Layout = ({
  children,
  footerClassName,
  withHeaderTel = false,
  locale = 'en',
  isHome,
  crawlable,
  isBrandPage,
  isFullBleed,
  btnClassName,
  statusCode,
  renderReviewJsonLd,
  homeVariation,
  isArticleOrBlogPage = false,
}) => {
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [sideBarInit, setSideBarInit] = useState(false)
  const [floatingFooterFlag, setFloatingFooterFlag] = useState('')
  const scrollPos = useRef(0)
  const [isOverHeader, setIsOverHeader] = useState(true)
  const leadId = useLeadId()
  const { track } = useAnalytics()
  const pznData = usePersonalizedData()
  const trustStripsData = useTrustStripsData()

  const isFloatingFooterEnabled =
    !isHome && floatingFooterFlag === 'floating_menu' && locale === 'en'

  const [onViewPort] = useOnViewPortCtas(
    [
      'apply-url',
      'title-image',
      'heroPrimaryBtnContent',
      'hero-consumer-concept-cta',
      'hero-slider-cta',
      'hero-icons-strip',
    ],
    'h1'
  )

  const handleClick = useCallback(() => {
    setSideBarOpen(!sideBarOpen)
    setSideBarInit(true)
    const data = {
      nav_link_section: 'Header',
      click_type: 'Menu Click',
      click_id: 'Menu',
      click_text: 'FDR-Web | Menu',
      track_event: 'global_header',
    }
    track(
      data,
      {
        event_type: 'track',
      },
      'click',
      statusCode
    )
  }, [sideBarOpen, track, statusCode])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    sideBarOpen
      ? body.classList.add('h-screen', 'overflow-hidden')
      : body.classList.remove('h-screen', 'overflow-hidden')
  }, [sideBarOpen])

  const scrollTrack = () => {
    setIsOverHeader(
      window.scrollY > 0 ? scrollPos.current < window.scrollY : true
    )
    scrollPos.current = window.scrollY
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollTrack)

    return () => window.removeEventListener('scroll', scrollTrack)
  }, [])

  const { view } = useAnalytics()
  const { setVariations } = useFeatureFlags()
  const pageLoaded = useRef(false)

  const setViewExperiments = useCallback(async () => {
    const experiments = []

    if (!!pznData && !!homeVariation) {
      experiments.push({
        experimentSection:
          experimentSectionByVariation[featureToggles.HOMEPAGE_PERSONALIZATION],
        variation: pznData.variation,
      })
    }

    try {
      const flags = [
        featureToggles.FLOATING_HEADER_FOOTER,
        featureToggles.CONSENT_BANNER,
      ]

      if (isArticleOrBlogPage)
        flags.push(featureToggles.ARTICLES_AND_BLOGS_SLIDER_CTA)

      const response = await axios.post('/api/ab-testing/', {
        flags,
        leadId: leadId,
      })

      const featuresFlag = response.data?.features || {}
      setFloatingFooterFlag(
        featuresFlag[featureToggles.FLOATING_HEADER_FOOTER] || 'control'
      )

      experiments.push({
        experimentSection:
          experimentSectionByVariation[featureToggles.CONSENT_BANNER],
        variation: featuresFlag[featureToggles.CONSENT_BANNER]
          ? 'test'
          : 'control',
      })

      experiments.push({
        experimentSection:
          experimentSectionByVariation[featureToggles.FLOATING_HEADER_FOOTER],
        variation:
          featuresFlag[featureToggles.FLOATING_HEADER_FOOTER] || 'control',
      })

      if (isArticleOrBlogPage) {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[
              featureToggles.ARTICLES_AND_BLOGS_SLIDER_CTA
            ],
          variation:
            featuresFlag[featureToggles.ARTICLES_AND_BLOGS_SLIDER_CTA] ||
            'control',
        })
      }

      if (isHome && homeVariation === 'control') {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[featureToggles.TRUST_STRIPS],
          variation: trustStripsData.variation?.toLowerCase() || 'control',
        })
      }
    } catch (err) {
      experiments.push({
        experimentSection:
          experimentSectionByVariation[featureToggles.FLOATING_HEADER_FOOTER],
        variation: 'control',
      })

      if (isArticleOrBlogPage) {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[
              featureToggles.ARTICLES_AND_BLOGS_SLIDER_CTA
            ],
          variation: 'control',
        })
      }

      if (isHome && homeVariation === 'control') {
        experiments.push({
          experimentSection:
            experimentSectionByVariation[featureToggles.TRUST_STRIPS],
          variation: 'control',
        })
      }
    }

    pageLoaded.current = true
    setVariations(experiments)
    view(statusCode, {}, null, experiments)
  }, [homeVariation, leadId, setVariations, view, statusCode])

  useEffect(() => {
    if (pageLoaded.current) return
    setViewExperiments()
  }, [setViewExperiments])

  return (
    <>
      {renderReviewJsonLd ? (
        <CustomJsonLd id="review" data={reviewsJsonLdData()} />
      ) : null}
      <div
        className={`${styles.layout} ${
          sideBarInit ? (sideBarOpen ? styles.menuOpen : styles.menuClosed) : ''
        }`}
      >
        <div className={styles.site}>
          <div className={`flex justify-start lg:justify-center print:hidden`}>
            {/* Mobile Menu Button */}
            <button
              type="button"
              onClick={handleClick}
              className={`fixed top-5 z-101 pl-2 text-blue-300  focus:outline-none lg:hidden ${
                !isOverHeader ||
                onViewPort ||
                !isFloatingFooterEnabled ||
                isHome
                  ? ''
                  : 'hidden'
              }`}
              aria-label="menu toggle"
            >
              <svg className="fill-current h-6 w-6" viewBox="0 0 24 24">
                {/* Show X button */}
                {sideBarOpen ? (
                  <path
                    fillRule="evenodd"
                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  />
                )}
              </svg>
            </button>

            <Header
              sideBarOpen={sideBarOpen}
              setSideBarOpen={setSideBarOpen}
              withHeaderTel={withHeaderTel}
              locale={locale}
              btnClassName={btnClassName}
              statusCode={statusCode}
              elementsOnViewport={onViewPort}
              isOverHeader={isOverHeader}
              isHome={isHome}
              isArticleOrBlogPage={isArticleOrBlogPage}
              isFloatingFooterEnabled={isFloatingFooterEnabled}
              floatingFooterFlag={floatingFooterFlag}
            />
          </div>
          <main className={'mt-20 lg:mt-0'}>{children}</main>
          <Footer
            footerClassName={footerClassName}
            locale={locale}
            crawlable={crawlable}
            isHome={isHome}
            isBrandPage={isBrandPage}
            statusCode={statusCode}
            isFullBleed={isFullBleed}
            homeVariation={homeVariation}
          />
        </div>
      </div>
    </>
  )
}

Layout.defaultProps = {
  locale: 'en',
  footerClassName: '',
  withHeaderTel: true,
  crawlable: false,
  isBrandPage: false,
  isHome: false,
  isArticleOrBlogPage: false,
  isFullBleed: false,
  btnClassName: undefined,
  renderReviewJsonLd: true,
  experiments: [],
}

Layout.propTypes = {
  locale: propTypes.string,
  withHeaderTel: propTypes.bool,
  children: renderable.isRequired,
  footerClassName: propTypes.string,
  crawlable: propTypes.bool,
  isBrandPage: propTypes.bool,
  isHome: propTypes.bool,
  isFullBleed: propTypes.bool,
  isArticleOrBlogPage: propTypes.bool,
  btnClassName: propTypes.string,
  statusCode: propTypes.number,
  renderReviewJsonLd: propTypes.bool,
  experiments: propTypes.array.isRequired,
}

export default React.memo(Layout)
